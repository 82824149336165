<template>
  <div class="livecourse d-flex align-items-center">
    <div
      class="livecourse-dot"
      :class="{
        hasPassed: data.state === 'hasPassed',
      }"
    ></div>
    <div
      class="livecourse-card position-relative d-flex bg-white align-items-center cursor-pointer p-3"
    >
      <div class="flex-fill">
        <div
          class="title text-18 font-weight-bold text-primary d-flex align-items-center"
        >
          <i class="icon-live-stream mr-1"></i>推薦直播講堂
        </div>
        <hr class="card-hr" />
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <div class="course-name text-12">【{{ data.liveCourseTag }}】</div>
            <div class="course-sub-name font-weight-bold">
              {{ data.liveCourseName }}
            </div>
          </div>
          <b-button
            variant="primary"
            size="md"
            class="btn-go-livecourse ml-2"
            @click="gotLiveCourse"
          >
            {{ $t('前往') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    gotLiveCourse() {
      return this.$router.push({
        name: 'liveList',
        query: {
          search: this.data.liveCourseName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.livecourse {
  margin-left: 12px;
  @media screen and (min-width: 768px) {
    margin-right: 12px;
  }
  &:not(:first-of-type) {
    margin-top: 16px;
  }
  .livecourse-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 12px;
    background: $line-2;
    position: relative;
    z-index: 1;
    &.isActive {
      background: $primary;
    }
    &.hasPassed {
      background: $secondary;
    }
  }
}
.livecourse-card {
  box-shadow: $card-box-shadow;
  flex: 1;
  border-radius: $rounded-md 0px 0px$rounded-md;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    border-radius: $card-border-radius;
    padding-right: 12px;
    position: relative;
  }
  .title {
    line-height: 30px;
    .icon-live-stream {
      font-size: 30px;
    }
  }
  .card-hr {
    margin: 12px 0;
  }
  .course-name {
    color: $font-grayscale-1;
  }
  .btn-go-livecourse {
    flex-shrink: 0;
    height: 38px;
  }
}
</style>
