<template>
  <div class="condition d-flex align-items-center">
    <div
      class="condition-dot"
      :class="{
        hasPassed: condition.state === 'hasPassed',
      }"
    ></div>
    <div class="condition-card position-relative d-flex align-items-center">
      {{
        $t('達成 ') +
        rankList[condition.rank].label +
        $t(' 檢定「通關 AI 對弈 Lv.') +
        condition.aiId +
        $t('」條件')
      }}
    </div>
  </div>
</template>

<script>
import rankList from '@/json/rank.json';

export default {
  props: {
    condition: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      rankList,
    };
  },
};
</script>

<style lang="scss" scoped>
.condition {
  margin-left: 12px;
  @media screen and (min-width: 768px) {
    margin-right: 12px;
  }
  &:not(:first-of-type) {
    margin-top: 16px;
  }
  .condition-card {
    @media screen and (min-width: 768px) {
      padding-left: 12px;
    }
  }
  .condition-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 12px;
    background: $line-2;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    &.hasPassed {
      background: $secondary;
    }
  }
}
</style>
