<template>
  <div class="ai-character d-flex align-items-center">
    <div
      class="ai-character-dot"
      :class="{
        isActive: aiCharacter.state === 'isActive',
        hasPassed: aiCharacter.state === 'hasPassed',
      }"
    ></div>
    <div
      class="ai-character-card position-relative d-flex bg-white align-items-center cursor-pointer"
      :class="{isSelected: isSelected}"
    >
      <div
        class="position-relative"
        :class="{'wrap-img': aiCharacter.state === 'isLock'}"
      >
        <img :srcset="avatarSrc" class="img-avatar" />
        <span
          v-show="aiCharacter.state === 'isLock'"
          class="icon-Lock position-absolute"
        ></span>
        <span v-show="isSelected" class="icon-Colorful-correct">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
      <div class="flex-fill">
        <div class="name">
          {{ 'Lv.' + aiCharacter.id }}
          {{ aiCharacter.opponentName }}
        </div>
        <div
          v-if="aiCharacter.state === 'isActive' && !isLastAi"
          class="condition text-12"
        >
          <template v-if="aiCharacter.streakCount > 1">
            {{
              $t('連贏') +
              aiCharacter.streakCount +
              $t('次解鎖下一關(') +
              userRecord.streakCount +
              '/' +
              aiCharacter.streakCount +
              ')'
            }}
          </template>
          <template v-else>
            {{ $t('獲勝即解鎖下一關') }}
          </template>
        </div>
      </div>
      <div
        v-if="$device.isMobile && aiCharacter.state === 'isActive'"
        class="btn-battle d-flex justify-content-center align-items-center"
      >
        <span class="icon-Battle"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aiCharacter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    userRecord: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isLastAi: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarSrc() {
      if (this.aiCharacter.state === 'isLock') {
        return require('@/assets/img/personal/avatar/avatar_none.png?srcset');
      } else {
        return require(`@/assets/img/personal/avatar/avatar_tsumego_ai_${
          this.aiCharacter.photoNumber || '1'
        }.png?srcset`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ai-character {
  margin-left: 12px;
  @media screen and (min-width: 768px) {
    margin-right: 12px;
  }
  &:not(:first-of-type) {
    margin-top: 16px;
  }
  .ai-character-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 12px;
    background: $line-2;
    position: relative;
    z-index: 1;
    &.isActive {
      background: $primary;
    }
    &.hasPassed {
      background: $secondary;
    }
  }
}
.ai-character-card {
  box-shadow: $card-box-shadow;
  flex: 1;
  border-radius: $rounded-md 0px 0px$rounded-md;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    border-radius: $card-border-radius;
    padding-right: 12px;
    position: relative;
    &.isSelected {
      &::after {
        border-radius: $card-border-radius;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 0 4px inset $positive;
      }
    }
  }
  .wrap-img {
    &::after {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0.3;
      top: 0;
      left: 0;
      right: 12px;
      bottom: 0;
    }
  }
  .img-avatar {
    margin-right: 12px;
    width: 80px;
    height: 80px;
    position: relative;
  }
  .icon-Lock {
    font-size: 32px;
    color: white;
    top: calc(40px - 16px);
    left: calc(40px - 16px);
    z-index: 1;
  }
  .icon-Colorful-correct {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      font-size: 18px;
      position: absolute;
      bottom: 5px;
      right: 13px;
      &.path2 {
        position: absolute;
        top: 0;
        left: 0;
        &.path2::before {
          margin-left: 0;
        }
      }
    }
  }
  .name {
    line-height: 28px;
  }
  .condition {
    color: $font-grayscale-1;
  }
  .btn-battle {
    background: $primary;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: $rounded-sm;
    .icon-Battle {
      font-size: 32px;
      color: white;
    }
  }
}
</style>
