var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap-ai-game-list h-100 overflow-hidden",class:{'overflow-hidden': _vm.isLoading}},[(_vm.$device.isMobile)?_c('BaseHeader',{attrs:{"title":_vm.$isJungo ? _vm.$t('AI對弈') : _vm.$t('下棋'),"is-title-bold":_vm.$isJungo,"has-red-dot":_vm.hasVerificationNotification,"left-icon":_vm.$isJungo ? 'Chevron-left' : 'Menu',"back-on-click-left":false},on:{"on-click-left":_vm.onClickSidebar}}):_vm._e(),_c('div',{staticClass:"ai-game-list scroll-bar"},[(_vm.isListLoading)?_c('loading-wrapper',{staticClass:"loading"}):(_vm.permission?.aiGame)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"current-plan text-18 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentPlan.name)+" ")]),_vm._l((_vm.aiGameCourseConfigs),function(aiCharacter,index){return [(aiCharacter.id)?_c('AiCharacterCard',{key:aiCharacter.id,class:{
              'is-chosen': _vm.$device.isMobile
                ? aiCharacter.id === _vm.userRecord.id - 1
                : aiCharacter.id === _vm.selectedAiCharacterId - 1,
            },attrs:{"ai-character":aiCharacter,"user-record":aiCharacter.id === _vm.userRecord.id ? _vm.userRecord : null,"is-selected":_vm.selectedAiCharacterId === aiCharacter.id,"is-last-ai":index === _vm.aiGameCourseConfigs.length - 1},nativeOn:{"click":function($event){return _vm.goAiGame(aiCharacter)}}}):(aiCharacter.aiId)?_c('ConditionCard',{key:'condition' + aiCharacter.rank,attrs:{"condition":aiCharacter}}):_vm._e(),(
              aiCharacter.liveCourseName &&
              _vm.expiredDay > 0 &&
              aiCharacter.state === 'hasPassed'
            )?_c('LiveCourseCard',{key:'liveCourse' + index,attrs:{"data":aiCharacter}}):_vm._e()]})],2):_c('div',{staticClass:"empty-placeholder"},[_c('EmptyPlaceholder',{attrs:{"icon":"Kifu2","text":_vm.$t('當前課程不開放{type}', {
              type: _vm.$t('下棋'),
            }),"sub-text":_vm.$t('您可以切換其他課程或前往官網購買')}}),_c('b-button',{staticClass:"mt-3 mx-auto d-block",attrs:{"variant":"primary","size":"md"},on:{"click":_vm.onClick2Web}},[_vm._v(" "+_vm._s(_vm.$t('前往官網了解'))+" ")])],1)],1),(!_vm.$device.isMobile)?_c('b-button',{staticClass:"btn-start w-100 mt-3",attrs:{"variant":"primary","size":"lg","disabled":!_vm.permission?.aiGame},on:{"click":function($event){return _vm.createAiGame()}}},[_vm._v(" "+_vm._s(_vm.$isJungo ? '対局開始' : _vm.$t('開始'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }