<template>
  <div
    class="wrap-ai-game-list h-100 overflow-hidden"
    :class="{'overflow-hidden': isLoading}"
  >
    <BaseHeader
      v-if="$device.isMobile"
      :title="$isJungo ? $t('AI對弈') : $t('下棋')"
      :is-title-bold="$isJungo"
      :has-red-dot="hasVerificationNotification"
      :left-icon="$isJungo ? 'Chevron-left' : 'Menu'"
      :back-on-click-left="false"
      @on-click-left="onClickSidebar"
    ></BaseHeader>
    <div class="ai-game-list scroll-bar">
      <loading-wrapper v-if="isListLoading" class="loading"></loading-wrapper>
      <div v-else-if="permission?.aiGame" class="content">
        <div class="current-plan text-18 font-weight-bold">
          {{ currentPlan.name }}
        </div>
        <template v-for="(aiCharacter, index) in aiGameCourseConfigs">
          <AiCharacterCard
            v-if="aiCharacter.id"
            :key="aiCharacter.id"
            :ai-character="aiCharacter"
            :user-record="aiCharacter.id === userRecord.id ? userRecord : null"
            :is-selected="selectedAiCharacterId === aiCharacter.id"
            :is-last-ai="index === aiGameCourseConfigs.length - 1"
            :class="{
              'is-chosen': $device.isMobile
                ? aiCharacter.id === userRecord.id - 1
                : aiCharacter.id === selectedAiCharacterId - 1,
            }"
            @click.native="goAiGame(aiCharacter)"
          ></AiCharacterCard>
          <ConditionCard
            v-else-if="aiCharacter.aiId"
            :key="'condition' + aiCharacter.rank"
            :condition="aiCharacter"
          ></ConditionCard>
          <LiveCourseCard
            v-if="
              aiCharacter.liveCourseName &&
              expiredDay > 0 &&
              aiCharacter.state === 'hasPassed'
            "
            :key="'liveCourse' + index"
            :data="aiCharacter"
          ></LiveCourseCard>
        </template>
      </div>
      <div v-else class="empty-placeholder">
        <EmptyPlaceholder
          icon="Kifu2"
          :text="
            $t('當前課程不開放{type}', {
              type: $t('下棋'),
            })
          "
          :sub-text="$t('您可以切換其他課程或前往官網購買')"
        />
        <b-button
          variant="primary"
          size="md"
          class="mt-3 mx-auto d-block"
          @click="onClick2Web"
        >
          {{ $t('前往官網了解') }}
        </b-button>
      </div>
    </div>
    <b-button
      v-if="!$device.isMobile"
      variant="primary"
      size="lg"
      class="btn-start w-100 mt-3"
      :disabled="!permission?.aiGame"
      @click="createAiGame()"
    >
      {{ $isJungo ? '対局開始' : $t('開始') }}
    </b-button>
  </div>
</template>

<script>
import _ from 'lodash';

import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import AiCharacterCard from '@/components/Practice/AiCharacterCard';
import ConditionCard from '@/components/Practice/ConditionCard';
import LiveCourseCard from '@/components/Practice/LiveCourseCard';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';

import aiGameService from '@/services/aiGame.js';
import verificationService from '@/services/verification.js';
import {Browser} from '@capacitor/browser';
import BackEvent from '@/lib/base/backEvent.js';
import {manifest} from '@/constant/env.js';

export default {
  name: 'AiGame',
  components: {
    BaseHeader,
    AiCharacterCard,
    ConditionCard,
    LiveCourseCard,
    LoadingWrapper,
    EmptyPlaceholder,
  },
  data() {
    return {
      aiGameCourseConfigs: [],
      userRecord: {},
      verificationConditions: [],
      selectedAiCharacterId: 1,
      isListLoading: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    permission() {
      return this.$store.getters['env/permission'];
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    expiredDay() {
      return this.$store.getters['user/liveCourseExpiredDay'];
    },
  },
  async created() {
    if (!this.permission?.aiGame) {
      return;
    }
    this.isListLoading = true;
    this.initalPage();
  },
  methods: {
    initalPage() {
      try {
        Promise.all([
          this.getAiGameCourseConfigs(),
          this.getAiGameUserRecord(),
          this.getVerificationConditions(),
        ]).then(
          ([aiGameCourseConfigs, userRecords, verificationConditions]) => {
            aiGameCourseConfigs.forEach((aiCharacter) => {
              // 將列表跟檢定條件與使用者目前紀錄做比對，填入狀態
              if (aiCharacter.id < userRecords.id) {
                aiCharacter.state = 'hasPassed';
              } else if (aiCharacter.id === userRecords.id) {
                aiCharacter.state = 'isActive';
              } else {
                aiCharacter.state = 'isLock';
              }
            });
            verificationConditions.forEach((condition) => {
              if (condition.aiId < userRecords.id) {
                condition.state = 'hasPassed';
              } else {
                condition.state = 'isLock';
              }
            });

            this.userRecord = userRecords;
            this.verificationConditions = verificationConditions;

            // 將檢定條件穿插進ai對弈列表中
            this.verificationConditions.forEach((condition) => {
              aiGameCourseConfigs.forEach((aiCharacter, index) => {
                if (aiCharacter.id === condition.aiId) {
                  aiGameCourseConfigs.splice(index + 1, 0, condition);
                }
              });
            });
            this.aiGameCourseConfigs = aiGameCourseConfigs;

            this.isListLoading = false;
            this.setSeclectedAiCharacter();
          }
        );
      } catch (error) {
        this.isListLoading = false;
        this.$Message.error(this.$t('發生未知錯誤，再試一次'), 3000);
      }
    },
    async getAiGameCourseConfigs() {
      const result = await aiGameService.getAiGameCourseConfigs(
        this.currentPlan.id
      );
      return result;
    },
    async getAiGameUserRecord() {
      const result = await aiGameService.getAiGameUserRecord(
        this.currentPlan.id
      );
      return result;
    },
    async getVerificationConditions() {
      const result = await verificationService.getConditions(
        this.currentPlan.id,
        'practice'
      );
      return result;
    },
    setSeclectedAiCharacter() {
      const selectedAiCharacter = JSON.parse(
        localStorage.getItem('selectedAiCharacter')
      );
      if (selectedAiCharacter) {
        // 因課程切換可能會某課程尚未通過某個ai，檢查在列表中是否是pass或active狀態
        const _selectedAiCharacter = this.aiGameCourseConfigs.find(
          (aiCharacter) => {
            return aiCharacter.id === selectedAiCharacter.id;
          }
        );
        if (_selectedAiCharacter && _selectedAiCharacter.state !== 'isLock') {
          this.selectedAiCharacterId = selectedAiCharacter.id;
          this.locateToSelectedAiCharacter();
        } else {
          this.onSelectedAiCharacter(this.aiGameCourseConfigs[0]);
        }
      } else {
        this.onSelectedAiCharacter(this.aiGameCourseConfigs[0]);
      }
    },
    goAiGame(aiCharacter) {
      if (aiCharacter.state === 'isLock') return;
      if (!this.$device.isMobile) {
        this.onSelectedAiCharacter(aiCharacter);
      } else {
        this.onSelectedAiCharacter(aiCharacter);
        this.createAiGame(aiCharacter);
      }
    },
    async createAiGame(aiCharacter = null) {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      if (!aiCharacter) {
        aiCharacter = this.aiGameCourseConfigs.find((ai) => {
          return this.selectedAiCharacterId === ai.id;
        });
      }
      const userColor = _.sample(['black', 'white']);
      const boardSize = aiCharacter.boardSize;
      const aiLevel = aiCharacter.aiLevel;
      const style = aiCharacter.style;
      const opponentName = aiCharacter.opponentName;
      const aiId = aiCharacter.id;
      const data = {
        userColor,
        aiLevel,
        boardSize,
        style,
        opponentName,
        coursePlan: this.currentPlan.id,
        aiId,
      };
      await this.$store.dispatch('aiGame/createPracticeGame', data);
      BackEvent.clearEvents();
      this.$store.commit('env/setIsLoading', false);
      this.$router.push('/practice/game');
    },
    onSelectedAiCharacter(aiCharacter) {
      this.selectedAiCharacterId = aiCharacter.id;
      this.$emit('onSelectedAiCharacter', aiCharacter);
      localStorage.setItem(
        'selectedAiCharacter',
        JSON.stringify({
          ...aiCharacter,
        })
      );
    },
    locateToSelectedAiCharacter() {
      setTimeout(() => {
        if (document.querySelector('.is-chosen')) {
          document.querySelector('.is-chosen').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 10);
    },
    onClickSidebar() {
      if (this.$isJungo) {
        this.$router.back(-1);
      } else {
        this.$store.commit('env/setIsSidebarShow', true);
        BackEvent.addEvent(() => {
          this.$store.commit('env/setIsSidebarShow', false);
        });
      }
    },
    onClick2Web() {
      const homePageUrl = manifest.homePageUrl;

      Browser.open({
        url: homePageUrl + '/courses',
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-ai-game-list {
  .current-plan {
    margin: 0px 49px 12px;
  }
}
.ai-game-list {
  height: calc(100% - 56px);
  overflow: overlay;
  position: relative;
  .content {
    padding: 12px 0 16px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      background: $line-2;
      top: 0;
      left: 18px;
    }
  }
  .empty-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 240px;
    margin: 0 auto;
    ::v-deep {
      .empty {
        height: auto !important;
      }
      .icon-Kifu2,
      p {
        text-align: center;
        color: $font-grayscale-2;
      }
    }
  }

  @media screen and (min-width: 768px) {
    background: $bgsection;
    border-radius: $rounded-sm;
    height: calc(100% - 48px - 16px);
  }
}
</style>
